<template>
  <div class="section">
    <b-container>
      <b-col md="6" class="mx-auto">
        <img class="img-fluid" src="../assets/images/404.svg" />
        <h1 class="mt-3">Not found!</h1>
        <p>
          <b>{{currentRoute.slice(1)}}</b> was not found! Try rechecking your url or
          <a href>reloading</a> this site. If you believe this is wrong, please email our
          <a
            :href="'mailto:' + localStorage.info.contact.email + ',dan@dancodes.online'"
          >support</a>.
        </p>
        <router-link to="/" class="btn-home">Head back Home</router-link>
      </b-col>
    </b-container>
  </div>
</template>
<style scoped lang="scss">
.btn-home {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 50px;
  border: 3px solid var(--second);
  background-color: var(--main);
  transition: 0.5s;
  margin: 0 20px 20px 0;
  color: var(--second);

  &:hover {
    background-color: var(--second);
    border: 3px solid var(--main);
    color: var(--second);
  }
}
.section {
  text-align: center;
  padding: 90px 0;
}
a {
  color: var(--main);
}
</style>
<script>
export default {
  data() {
    return {
      currentRoute: window.location.pathname
    };
  }
};
</script>