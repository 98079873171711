<template>
  <section id="contact">
    <b-row>
      <b-col md="8" class="mx-auto">
        <div class="container-fluid">
          <div class="section-header">
            <h3>Contact Us</h3>
          </div>

          <b-row>
            <b-col lg="6">
              <div class="map mb-4 mb-lg-0">
                <iframe
                  :src="
                    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.776636436575!2d20.956836215796624!3d52.229275779760165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb66f5f20951%3A0xa1464d484f92144e!2s' +
                      localStorage.info.contact.address.join(' ') +
                      '!5e0!3m2!1sen!2sch!4v1579960209627!5m2!1sen!2sch'
                  "
                  frameborder="0"
                  style="border:0; width: 100%; height: 312px;"
                  allowfullscreen
                ></iframe>
              </div>
            </b-col>

            <b-col lg="6">
              <div class="box">
                <b-row>
                  <b-col md="2" class="text-center">
                    <fa-icon
                      style="font-size:3vh; color:var(--main); max-width:80%; max-height:80%"
                      icon="map-marker-alt"
                    ></fa-icon>
                  </b-col>
                  <b-col md="10" class="tex">{{ localStorage.info.contact.address.join(", ") }}</b-col>
                </b-row>
              </div>
              <div class="box">
                <b-row>
                  <b-col md="2" class="text-center">
                    <fa-icon
                      style="font-size:3vh; color:var(--main); max-width:80%; max-height:80%"
                      icon="phone"
                    ></fa-icon>
                  </b-col>
                  <b-col md="10" class="tex">{{ localStorage.info.contact.phone }}</b-col>
                </b-row>
              </div>
              <div class="box">
                <b-row>
                  <b-col md="2" class="text-center">
                    <fa-icon
                      style="font-size:3vh; color:var(--main); max-width:80%; max-height:80%"
                      icon="envelope"
                    ></fa-icon>
                  </b-col>
                  <b-col md="10" class="tex">{{ localStorage.info.contact.email }}</b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </section>
</template>
<script>
export default {
  mounted() {
    return (document.title = "Tomalex - Contact");
  }
};
</script>
<style scoped lang="scss">
@media (max-width: 768px) {
  .tex {
    padding-top: 10px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .tex {
    margin-left: -1vw;
  }
}
#contact {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 100px 0;
  overflow: hidden;

  .section-header {
    padding-bottom: 30px;
  }

  .contact-about {
    h3 {
      font-size: 36px;
      margin: 0 0 10px 0;
      padding: 0;
      line-height: 1;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: var(--main);
    }

    p {
      font-size: 14px;
      line-height: 24px;
      font-family: "Montserrat", sans-serif;
      color: #888;
    }
  }

  .social-links {
    padding-bottom: 20px;

    a {
      font-size: 18px;
      display: inline-block;
      background: var(--second);
      color: var(--main);
      line-height: 1;
      padding: 8px 0;
      margin-right: 4px;
      border-radius: 50%;
      text-align: center;
      width: 36px;
      height: 36px;
      transition: 0.3s;
      border: 1px solid var(--main);

      &:hover {
        background: var(--main);
        color: var(--second);
      }
    }
  }

  .info {
    color: #283d50;

    i {
      font-size: 32px;
      color: var(--main);
      float: left;
      line-height: 1;
    }

    p {
      padding: 0 0 10px 36px;
      line-height: 28px;
      font-size: 14px;
    }
  }

  .form {
    #sendmessage {
      color: var(--main);
      border: 1px solid var(--main);
      display: none;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    #errormessage {
      color: red;
      display: none;
      border: 1px solid red;
      text-align: center;
      padding: 15px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    #sendmessage.show,
    #errormessage.show,
    .show {
      display: block;
    }

    .validation {
      color: red;
      display: none;
      margin: 0 0 20px;
      font-weight: 400;
      font-size: 13px;
    }

    input,
    textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
    }

    button[type="submit"] {
      background: var(--main);
      border: 0;
      border-radius: 20px;
      padding: 8px 30px;
      color: var(--second);
      transition: 0.3s;

      &:hover {
        background: #0067d5;
        cursor: pointer;
      }
    }
  }
}
</style>
